import React from "react"
import { graphql } from "gatsby"
import {RiSendPlane2Line} from "react-icons/ri";

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query ContactQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({data}) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return  (
    <Layout className="contact-page">
      <SEO
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
        norobots={true}
      />
      <div className="wrapper">
        <h1>{frontmatter.title}</h1>
        <div className="description" dangerouslySetInnerHTML={{ __html: html }} />
        <form className="contact-form" action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfH_nR4FNoCRupagsR5J5gmsIIfWoNMcl5MVf9FkDahltmKwA/formResponse" method="post">
          <p>
            <label>お名前<input type="text"  name="entry.1531089338" id="name" required pattern=".{2,}" placeholder="例) 山田 花子" /></label>
          </p>
          <p>
            <label>メールアドレス<input type="email"  name="entry.1550085765" id="email" required pattern=".+@.+\..+" title="メールアドレスは、aaa@example.com のような形式で記入してください。" placeholder="例) example@mail.co.jp" /></label>
          </p>
          <p>お問い合わせの種類</p>
          <div className="subject">
            <label for="work">
              <input id="work" name="entry.1146965042" type="radio" value="お仕事の相談" required /><span>お仕事の相談</span>
            </label>
            <label for="opinion">
              <input id="opinion" name="entry.1146965042" type="radio" value="ご意見・ご感想" /><span>ご意見・ご感想</span>
            </label>
            <label for="other">
              <input id="other" name="entry.1146965042" type="radio" value="その他" /><span>その他</span>
            </label>
          </div>
          <p>
            <label>お問い合わせ内容<textarea name="entry.1497836627" id="message" required minlength="20" title="20文字以上ご入力ください。" placeholder="ご自由に入力して下さい。"></textarea></label>
          </p>
          <p>
            <label>スパムロボット対策<input type="text" name="entry.1310426885" id="robot" required pattern="ねこ" title="入力内容を確認してください。" placeholder="ここにひらがなで「ねこ」と入力してください。" /></label>
          </p>
          <p className="text-align-right">
            <button className="button" type="submit">送信<span className="icon -right"><RiSendPlane2Line/></span></button>
          </p>
        </form>
      </div>

    </Layout>
  )
}

export default Contact